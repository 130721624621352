export default {
	async mounted() {
		this.addTooltip();
	},
	async updated() {
		this.addTooltip();
	},
	methods: {
		addTooltip() {
			// Show tooltip for long text strings
			const strings = document.querySelectorAll('[data-tooltip]');

			if (strings.length) {
				for (const text of strings) {
					if (text.scrollWidth > text.offsetWidth && !text.title) {
						text.title = text.innerText;
					}
				}
			}
		}
	}
};
