<template>
	<form action="/search" target="_top" class="search relative" @submit.prevent="onSearch">
		<input v-model="q" type="search" :autocomplete="autocomplete" name="q" :placeholder="placeholder" :maxlength="maxlength" :aria-label="placeholder" class="search__input w-100 h-100" />
		<button type="submit" class="search__button button--no-style flex flex--align p-2 h-100 pin-right-0"><span class="visually-hidden">Search</span><inline svg="search" class="h-100" /></button>
	</form>
</template>

<script>

	export default {
		name: 'Search',
		props: {
			placeholder: {
				type: String,
				default: 'Ask us anything'
			},
			maxlength: {
				type: String,
				default: '100'
			},
			autocomplete: {
				type: String,
				default: 'off'
			},
			value: {
				type: String,
				default: null
			},
			path: {
				type: String,
				default: '/search'
			}
		},
		data() {
			return {
				q: this.value
			};
		},
		watch: {
			value: function() {
				this.q = this.value;
			}
		},
		methods: {
			onSearch: function() {
				if (this.$route.query.q !== this.q.trim()) {
					this.$router.push({
						path: this.path,
						query: {
							q: this.q.trim()
						}
					});
					this.$emit('search', this.q);
				}
			}
		}
	};
</script>

<style lang="scss">
	.search {

		&__input {
			background: transparent;
			border: 0;
			padding: .5em 2.3em .5em .5em;
			color: inherit;
			-webkit-appearance: none;

			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
			}

			&:focus {
				outline: $thetis 1px solid;
			}

			&::placeholder {
				opacity: .7;
				color: inherit;
			}
		}

		&__button {
			top: 0;
			width: 2.5em;
			color: inherit;

			&:focus {
				background: rgba($thetis, .5);
				outline: none;
			}
		}

		&--animate {
			animation: search .25s ease forwards;

			@keyframes search {

				0% {
					opacity: 0;
					transform: scaleX(.3);
				}

				100% {
					opacity: 1;
					transform: scaleX(1);
				}
			}
		}
	}
</style>